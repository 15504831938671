<!--
  -- Created by zed on 2022/10/14
  -- 【商城广告页面】
-->

<template>
  <moe-page title="商城广告">
    <!-- 筛选信息 -->
    <moe-inquire @search="bannerSearch">
      <el-form-item label="广告编号">
        <el-input :value="bannerParams.id" placeholder="输入广告编号" @input="(value) => bannerParams.id = $moe_formatter.formatterId(value)" clearable />
      </el-form-item>
      <el-form-item label="状态">
        <moe-select v-model="bannerParams.showStatus" type="mallAdvertStatus" placeholder="选择状态" />
      </el-form-item>
    </moe-inquire>

    <!-- 商品信息 -->
    <moe-table ref="bannerTable" url="/shop/advert/queryShopAdvertList" :params="bannerParams" :numberShow="false">
      <!-- 操作按钮 -->
      <template slot="tool">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="$moe_coordinator.navigateTo('/goodsAdvert/add')">商城广告添加</el-button>
      </template>
      <el-table-column prop="id" label="广告编号" min-width="180" />
      <el-table-column prop="coverUrl" label="图片" width="180">
        <template slot-scope="{ row }">
          <moe-image :src="row.coverUrl" width="100px" height="100px" />
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="排序" min-width="100" />
      <el-table-column label="广告位置" width="100">
        <template slot-scope="{ row }">
          {{ $moe_format.getBannerType(row.type) }}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" min-width="150">
        <template slot-scope="{ row }">
          {{ $moe_time.getTimeStamp(row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column label="展示时间" min-width="310">
        <template slot-scope="{ row }">
          <div v-if="row.startTime">
            {{ `${$moe_time.getTimeStamp(row.startTime)} 至 ${$moe_time.getTimeStamp(row.endTime)}` }}
          </div>
          <div v-else>长期</div>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="90">
        <template slot-scope="{ row }">
          <div :class="$moe_format.getMallContentStatusColor(row.showStatus)">
            {{ $moe_format.getMallContentStatus(row.showStatus) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="400" fixed="right">
        <div class="moe-table__btns" slot-scope="{ row }">
          <el-button type="success" size="mini" icon="el-icon-view" @click="$moe_coordinator.navigateTo(`/goodsAdvert/detail`, { id: row.id })">详情</el-button>
          <el-button type="primary" size="mini" icon="el-icon-edit" @click="$moe_coordinator.navigateTo(`/goodsAdvert/add`, { id: row.id })">编辑</el-button>
          <el-button v-if="['RUNNING'].includes(row.showStatus)" type="warning" size="mini" icon="el-icon-video-pause" @click="handleShelf(row, 2, '暂停')">暂停</el-button>
          <el-button v-if="['STOP'].includes(row.showStatus)" type="info" size="mini" icon="el-icon-video-play" @click="handleShelf(row, 1, '启用')">启用</el-button>
          <el-button type="danger" size="mini" icon="el-icon-delete" @click="handleDelete(row.id)">删除</el-button>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
export default {
  name: 'GoodsAdvert',
  data() {
    //日志筛选信息
    let bannerParams = {
      id: '',
      pageNum: 1, //当前页
      pageSize: 10, //每页条数
      status: '',
    };
    return {
      bannerParams, //日志筛选信息
    };
  },
  methods: {
    /**
     * 商品搜索
     **/
    bannerSearch(isSearch) {
      if (!isSearch) {
        this.bannerParams = {
          id: '',
          pageNum: 1, //当前页
          pageSize: 10, //每页条数
          status: '',
        };
      }

      //刷新商品表格
      this.$refs.bannerTable.searchData();
    },
    /** 删除 */
    handleDelete(id) {
      this.$moe_layer.confirm(`您确定要删除当前选项吗？`, () => {
        this.$moe_api.BANNER_API.bannerDelete({ advertId: id }).then((data) => {
          if (data.code === 200) {
            this.$moe_msg.success('删除成功');
            this.$refs['bannerTable'].searchData();
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      })
    },
    /** 状态修改 */
    handleShelf({ id }, status, text) {
      this.$moe_layer.confirm(`您确定要"${text}"当前选项吗？`, () => {
        this.$moe_api.BANNER_API.bannerStatus({ id, status }).then((data) => {
          if (data.code == 200) {
            this.$moe_msg.success('操作成功');
            this.$refs['bannerTable'].loadingData();
          } else {
            this.$moe_msg.error(data.message);
          }
        });
      })
    },
  },
};
</script>